import { datadogRum } from '@datadog/browser-rum';
import { Plugin } from '@nuxt/types';
import { getWLAuth0CookieDomain } from '@white-label-helper/get-wl-auth0-strategy';
import { parse as cookieParse, serialize as cookieSerialize } from 'cookie'
// @ts-expect-error - This is a custom import
import { withoutTrailingSlash } from '~i18n-ufo'
import JsCookie from 'js-cookie'
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter, { RawLocation } from 'vue-router';

import locales from './locales'

Vue.use(VueI18n)

type CavuLocalMessage = string | CavuLocaleMessageObject | CavuLocaleMessageArray;
type CavuLocaleMessageArray = { [index: number]: CavuLocalMessage; }
type CavuLocaleMessageObject = { [key: string]: CavuLocalMessage }

/**
 *
 * @param context
 * @param inject
 */
export const partnerConfigClientPlugin: Plugin = async (context) => {
  let partnerConfig = context.$partnerConfig;
  let initialLocaleSet = false;

  // Developer Note:
  // This should allow backwards compatibility with the old way of loading the partner configuration
  if (partnerConfig === undefined) {
    partnerConfig = JSON.parse(process.env.NUXT_ENV_PARTNER_CONFIG_JSON)
  }

  if (partnerConfig === undefined) {
    const error = new Error('Partner configuration not found');
    datadogRum.addError(error);
    throw error
  }

  if (Array.isArray(partnerConfig.languages) === false) {
    const error = new Error('Partner configuration languages not found');
    datadogRum.addError(error);
    throw error
  }

  const languageCodes = partnerConfig.languages?.map((language) => language.code);

  const filteredLocales = locales.filter((locale) => languageCodes.includes(locale.code));

  const localeMessagesReadyToImport = filteredLocales.reduce(
    (accumulator, locale) => {
      accumulator[locale.code] = () => import(`/codebuild/output/src371705884/src/cavu-white-label/applications/white-label-account/src/lang/${locale.file}`);
      return accumulator;
    },
    {} as Record<string, () => Promise<CavuLocaleMessageObject | (() => CavuLocaleMessageObject) | { default: CavuLocaleMessageObject | (() => CavuLocaleMessageObject) }>>
  );

  // set defaults
  let locale = 'en-US';
  let fallbackLocale = 'en-US';

  // override defaults
  if (typeof partnerConfig.default_language === 'string') {
    locale = partnerConfig.default_language;
    fallbackLocale = partnerConfig.default_language;
  }

  // create i18n instance
  const i18n = new VueI18n({});

  // set default falback locale
  i18n.fallbackLocale = fallbackLocale;

  const getLocaleCookie = () => {
    let localCode

    // Gets the cookie if on the client
    if (process.client) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      localCode = JsCookie.get(`i18n_language-${(partnerConfig as any).channel.token}`);
    }

    // Gets the cookie if on the server
    if (process.server) {
      const cookies = context.req.headers.cookie ? cookieParse(context.req.headers.cookie) : {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      localCode = cookies[`i18n_language-${(partnerConfig as any).channel.token}`]
    }

    return localCode;
  }

  const setLocaleCookie = (localeCode: string) => {
    const domain = getWLAuth0CookieDomain();

    // Sets the cookie if on the client
    if (process.client) {
      const cookieOptions = {
        domain,
        expires: Number('365'),
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      JsCookie.set(`i18n_language-${(partnerConfig as any).channel.token}`, localeCode, cookieOptions);
    }

    // Sets the cookie if on the server
    if (process.server) {
      let headers = context.req.getHeader('Set-Cookie') || [];

      if (Array.isArray(headers) === false) {
        headers = [String(headers)];
      }

      const cookieOptions = {
        domain,
        maxAge: 60 * 60 * 24 * Number('365'),
        path: '/',
        sameSite: 'lax',
        secure: false,
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const redirectCookie = cookieSerialize(`i18n_language-${(partnerConfig as any).channel.token}`, localeCode, cookieOptions);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      headers = headers.filter((header: any) => {
        const cookie = cookieParse(header);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (`i18n_language-${(partnerConfig as any).channel.token}` in cookie) === false;
      });
      headers.push(redirectCookie);

      context.res.setHeader('Set-Cookie', headers);
    }
  }

  const setLocale = async (localeCode: string) => {
    if (localeCode === undefined) return Promise.resolve();

    const currentLocale = i18n.locale;

    if (initialLocaleSet === true && currentLocale === localeCode) return Promise.resolve();

    setLocaleCookie(localeCode);
    await loadLanguageAsync(localeCode);
    initialLocaleSet = true;
    i18n.locale = localeCode;
  }

  const getRouteBaseName = (givenRoute?: { name: string }) => {
    const route = givenRoute ?? context.route
    if (!route?.name) {
      return
    }
    return route.name.split('___')[0]
  }

  const resolveRoute = (route: string, locale?: string) => {
    // Abort if no route or no locale
    if (!route) {
      return
    }

    locale = locale ?? i18n.locale

    if (!locale) {
      return
    }

    let localizedRoute: RawLocation

    // Check if it's a path or name of route.
    if (route.startsWith('/')) {
      // If route parameter is a path, create route object with path.
      localizedRoute = { path: route }
    } else {
      // Else use it as route name.
      localizedRoute = { name: route }
    }

    if ('path' in localizedRoute == true && 'name' in localizedRoute === false) {
      const resolvedRoute = context.app.router.resolve(localizedRoute).route
      const resolvedRouteName = getRouteBaseName(resolvedRoute)
      if (resolvedRouteName) {
        localizedRoute = {
          name: resolvedRouteName,
          params: resolvedRoute.params,
          query: resolvedRoute.query,
          hash: resolvedRoute.hash
        }
      } else {
        localizedRoute.path = withoutTrailingSlash(localizedRoute.path, true)
      }
    } else {
      if (!localizedRoute.name && !localizedRoute.path) {
        localizedRoute.name = getRouteBaseName()
      }

      const { params } = localizedRoute
      if (params && params['0'] === undefined && params.pathMatch) {
        params['0'] = params.pathMatch
      }
    }

    const resolvedRoute = (context.app.router as VueRouter).resolve(localizedRoute)
    if (resolvedRoute.route.name) {
      return resolvedRoute
    }
    // If unable to resolve to an existing route then just return resolved route based on original input.
    return (context.app.router as VueRouter).resolve(route)
  }

  const localePath = (route: string, locale?: string) => {
    const localizedRoute = resolveRoute.call(this, route, locale)
    return localizedRoute ? localizedRoute.route.redirectedFrom ?? localizedRoute.route.fullPath : ''
  }

  i18n.availableLocaleCodes = languageCodes;
  i18n.loadedLanguages = [];
  i18n.locales = filteredLocales;
  i18n.setLocale = setLocale;

  const loadLanguageAsync = async (localeCode: string) => {
    if (i18n.loadedLanguages.includes(localeCode)) return Promise.resolve();

    let messages;

    // If on the client then check for the messages in the nuxtState cache
    if (process.client) {
      messages = context.nuxtState.__i18n?.langs?.[localeCode];
    }

    // If messages are not found in the nuxtState cache then load them
    if (messages === undefined) {
      messages = await localeMessagesReadyToImport[localeCode]().then(m => {
        const loadedMessages = 'default' in m ? m.default : m;
        return typeof loadedMessages === 'function' ? loadedMessages() : loadedMessages;
      });
    }

    // Set cache to skip reloading the locale messages
    if (process.client) {
      context.nuxtState.__i18n = context.nuxtState.__i18n || {};
      context.nuxtState.__i18n.langs = context.nuxtState.__i18n.langs || {};
      context.nuxtState.__i18n.langs[localeCode] = messages;
    }

    i18n.setLocaleMessage(localeCode, messages);
    i18n.loadedLanguages.push(localeCode);
  }

  context.app.i18n = context.i18n = i18n;

  const localeToLoad = getLocaleCookie() || locale;

  Vue.use({
    install(Vue) {
      Vue.mixin({
        methods: {
          localePath,
        }
      })
    }
  })

  setLocale(localeToLoad);
}

export default partnerConfigClientPlugin;
