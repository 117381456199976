import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _73eb884c = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _350cab92 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _2237c021 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _e919abfc = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _afae8622 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _61c4e43d = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6db505e7 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _23b458e0 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _b0021838 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _489452e0 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _618d6f90 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _3595a30c = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _6ad17c40 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _830cc5a2 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _2c145270 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _6e9e2655 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _cfc57db4 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _73eb884c,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _350cab92,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _2237c021,
    name: "callback"
  }, {
    path: "/downloads",
    component: _e919abfc,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _afae8622,
    name: "guests-details"
  }, {
    path: "/login",
    component: _61c4e43d,
    name: "login"
  }, {
    path: "/maintenance",
    component: _6db505e7,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _23b458e0,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _b0021838,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _489452e0,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _618d6f90,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _3595a30c,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _6ad17c40,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _830cc5a2,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _2c145270,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _6e9e2655,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _cfc57db4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
